import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet"
import Page from "../components/project_a_muse_3.js"
import IndexSPA from "../components/IndexSPA"
import { Children } from "react";

// export default Page

const Index = (props) => {
   return (
      <>
        <Helmet
          title="柳隅茶社-藍子鈞 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽"
          meta={[
            {
              name: `description`,
              content: "位於龍山地下街的柳隅茶舍，有著捷運共構的便利性，獨特的空間體驗，還用特別的方式回饋地方行善，很難想像在台灣茶藝館越來越少的這個時代，店主人用文化導覽和在地的故事傳承，反倒建立了不可替代的觀光旅遊體驗。",
            },
            {
              property: `og:title`,
              content: "柳隅茶社-藍子鈞 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽",
            },
            {
              property: `og:description`,
              content: "位於龍山地下街的柳隅茶舍，有著捷運共構的便利性，獨特的空間體驗，還用特別的方式回饋地方行善，很難想像在台灣茶藝館越來越少的這個時代，店主人用文化導覽和在地的故事傳承，反倒建立了不可替代的觀光旅遊體驗。歐陽如修",
            },
            // {
            //   property: `og:url`,
            //   content: 'https://online.home-to-all.com' + window.location.pathname,
            // },
            {
              property: `og:image`,
              content: 'https://online.home-to-all.com' + require('../images/project_a_muse_3.jpg')
            }
          ]}
        >
        </Helmet>
         <IndexSPA />
         {/* <Page /> */}
      </>
   )
 };
 
 // Index.propTypes = {}
 
 export default Index;